@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

body, html {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.App {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #ffffff;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #e94560;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.user-card {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 40px 40px 20px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px; /* Adjust as needed */
}

.logo {
  width: 100%;
  max-width: 200px;
  margin: 0 auto 20px;
}

.logo img {
  width: 100%;
  height: auto;
}

.card-header {
  margin-bottom: 20px; /* Azaltılmış boşluk */
}

.card-footer {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  height: auto;
  width: 50%;
  max-width: 150px;
  object-fit: contain; 
}

.footer-text {
  font-size: 10px;
  color: #666;
  margin: 0;
  text-align: center;
  flex: 1;
  padding: 0 10px;
}

.partner-badge {
  height: 40px;
  width: auto;
}

@media (max-width: 480px) {
  .user-card {
    padding: 30px 20px 10px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-logo, .partner-badge {
    height: 40px;
    width: auto;
  }

  .footer-text {
    order: 3;
    margin-top: 10px;
  }
}

.avatar {
  width: 120px;
  height: 120px;
  background-color: #e94560;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: white;
  margin: 0 auto 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  margin: 0 0 10px;
  color: #333333;
  font-size: 28px;
  font-weight: 700;
}

.title {
  margin: 0;
  color: #e94560;
  font-size: 18px;
  font-weight: 400;
}

.contact-info {
  margin-top: 20px; /* Azaltılmış boşluk */
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0; /* Azaltılmış boşluk */
  color: #333333;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

.info-item:hover {
  color: #e94560;
  transform: translateY(-2px);
}

.info-item i {
  margin-right: 10px;
  font-size: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px; /* Azaltılmış boşluk */
}



.icon-item {
  color: #333333;
  font-size: 24px;
  text-decoration: none;
  transition: all 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.icon-item:hover {
  color: #e94560;
  transform: translateY(-2px);
}
.linkedin-group {
  display: flex;
  align-items: center;
}

.linkedin-group .icon-item {
  margin: 0 5px;
}
/* Tooltip styles */
.icon-item:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333333;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.icon-item:hover::after {
  opacity: 1;
}
/* Özel ikon renkleri */
.icon-item.website {
  color: #4285F4;
}

.icon-item.calendar {
  color: #34A853;
}

.icon-item.linkedin {
  color: #0077B5;
}

.icon-item.screenshot {
  color: #FBBC05;
}

.icon-item.share {
  color: #EA4335;
}

/* Hover efektleri */
.icon-item.website:hover,
.icon-item.calendar:hover,
.icon-item.linkedin:hover,
.icon-item.screenshot:hover,
.icon-item.share:hover {
  color: #333333;
}


.save-contact {
  background-color: #e94560;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px; /* Azaltılmış boşluk */
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.save-contact:hover {
  background-color: #333333;
  transform: translateY(-2px);
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);
}

.save-contact i {
  margin-right: 8px;
}

.not-found {
  text-align: center;
  font-size: 20px;
  color: white;
}
.footer-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

@media (max-width: 480px) {
  .social-icons {
    gap: 10px;
  }

  .footer-content {
    flex-direction: column;
    gap: 15px;
  }
  .footer-icons {
    order: 2;
  }

  .partner-badge {
    order: 3;
  }
  .App {
    padding: 10px;
  }
  
  .user-card {
    padding: 30px;
  }
  
  h1 {
    font-size: 24px;
  }
  
  .title {
    font-size: 16px;
  }
  
  .icon-item {
    font-size: 20px;
  }
  .info-item i {
    font-size: 18px;
  }
  
  .save-contact {
    font-size: 14px;
    padding: 10px 20px;
  }
}